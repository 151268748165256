import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useProfile } from '../context/UserContext';

type Props = {
  isAuthenticated: boolean;
};

const Navbar: React.FC<Props> = ({ isAuthenticated }) => {

  const { state } = useProfile();  
  return (
    <header>
      <div className="container">
        <div className="flex flex-wrap justify-between nav-b border-t-[1px] border-t-[#EAEAEA]">
          <div className="order-1 flex h-[65px] items-center md:h-[80px]">
            <Link href="/">
              <a className="w-[155px] leading-none md:w-[210px]">
                  <Image
                    className=""
                    alt="Showroom logotype"
                    src="/assets/Showroom_Logotype_Black.svg"
                    width="209"
                    height="44"
                  />
              </a>
            </Link>
          </div>
          <div className="order-2 flex h-[65px] items-center space-x-3 md:order-3 md:h-[80px]">
            {isAuthenticated && !!state.user ? (
              <>
                  <Link href={process.env.NEXT_PUBLIC_CMS_DOMAIN + '/showroom'}>
                    <a className="btn-black hidden md:block">Campaign Manager</a>
                  </Link>
              </>
            ) : (
              <>
                  <Link href={process.env.NEXT_PUBLIC_CMS_DOMAIN + '/login'}>
                    <a className="btn-black hidden md:block">Log in</a>
                  </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
