import React, { useState, useEffect, useRef } from 'react';
import Dialog from 'components/Dialog'

type Props = {};

const LeadPopups: React.FC<Props> = () => {
    // State
    const [formVariant, setFormVariant] = useState('')
    // Refs
    const dialogRef = useRef<React.ElementRef<typeof Dialog>>(null);

    useEffect(() => {
      window.addEventListener("showCreatorsContactForm", (e) => {
        setFormVariant('creator');
        dialogRef.current?.setOpen(true);
      }, false);
      window.addEventListener("showBrandsContactForm", (e) => {
        setFormVariant('brand');
        dialogRef.current?.setOpen(true);
      }, false);
    }, [])

    return (
        <>
            <Dialog
             ref={dialogRef}
                title={formVariant === 'brand' ? 'For Brands' : 'For Creators'}
                button={<button className="hidden"></button>}>
                <div className="flex justify-center w-full">
                    {
                        formVariant === 'brand'
                        ? <iframe
                            src="/mailchimp_brands.html"
                            width="100%"
                            height="350"
                            style={{ border: 0 }}></iframe>
                        : <iframe
                            src="/mailchimp_creators.html"
                            width="100%"
                            height="350"
                            style={{ border: 0 }}></iframe>
                    }
                </div>
            </Dialog>
        </>
    );
}

export default LeadPopups;